/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

          


(function($) {
  
  //SETTING COOKIE FUNCTIONS
          getCookie=function getCookie(name) {
            var dc = document.cookie;
            var prefix = name + "=";
            var begin = dc.indexOf("; " + prefix);
            if (begin == -1) {
              begin = dc.indexOf(prefix);
              if (begin != 0) return null;
            }
              else
                {
                  begin += 2;
                  var end = document.cookie.indexOf(";", begin);
                  if (end == -1) {
              end = dc.length;
                  }
                }
            return unescape(dc.substring(begin + prefix.length, end));
          } 


         getCookie2= function getCookie2(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for(var i=0; i<ca.length; i++) {
              var c = ca[i];
              while (c.charAt(0)==' ') c = c.substring(1);
              if (c.indexOf(name) == 0) {  return c.substring(name.length,c.length); } //alert(c.substring(name.length,c.length));
            }
              
            return "";
          } 

          //Set a cookie for checkout button on front end; display or not     
         setCookie2= function setCookie2(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires + ";" + "path=/";
          } 

          setSession = function setSession(cart_items) {
            // for (const [key, value] of Object.entries(cart_items)) {
            //   console.log(`${key}: ${value}`);
            //   var cookie = [name, '=', JSON.stringify(value), '; domain=.', window.location.host.toString(), '; path=/;'].join('');
            //   document.cookie = cookie;
            // }

            var cookie = ['cartSession', '=', JSON.stringify(cart_items), '; domain=.', window.location.host.toString(), '; path=/;'].join('');
            document.cookie = cookie;
          }

          getInCart = function getInCart(search_code) {
            var cart_items = document.cookie.match(new RegExp('cartSession' + '=([^;]+)'));
            console.log('cart item:' , cart_items);
            cart_items && (cart_items = JSON.parse(cart_items[1]));
            console.log('cart item after parse:' , cart_items);
            var count = 0;

            if (cart_items) {
              // for (const [key, value] of Object.entries(cart_items)) {
              //   console.log(`${key}: ${value}`);
              //   if (value.search_name === search_code) {
              //     count++;
              //   }
              // }

              // for (const key of Object.keys(cart_items)) {
              //   console.log(key, cart_items[key]);
              //   if (cart_items[key].search_name === search_code) {
              //     count++;
              //   }
              // }

              // $.each(obj, function(key, value) {
              //     console.log(key, value);
              // });
              
              for (var key in cart_items) {
                  if (!cart_items.hasOwnProperty(key)) {
                      //The current property is not a direct property of p
                      continue;
                  }
                  //Do your logic with the property here
                  console.log(key, cart_items[key], cart_items[key].search_name);

                  if (cart_items[key].search_name == search_code.toUpperCase()) {
                    count++;
                  }
              }
            }

            console.log('has ' + count + ' in cart');
            return count;
          }


                  //Fb like te
          //second ajax request to collect real-time likes and compare.
         fbLike2= function fbLike2(origcount) {
            var orig_count = origcount;
            var request = $.ajax({
              url: "/fblike.php?count="+orig_count,
              type: "GET",      
              dataType: "html"
            });

            request.done(function(msg) {
              $("#displayMsg").html(msg);      
            });

            request.fail(function(jqXHR, textStatus) {
              alert( "Request failed: " + textStatus );
            });
          }
  
  
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
//         console.log("do11");
        $('.nav-tabs').on('click', 'a', function(e){
	        e.preventDefault();
	        var li = $(this).parent('li');
	       
	        var ul = li.parent();
	        ul.find('li').removeClass('active');
	        li.addClass('active');
	        var container = $(this).closest('.nav-tabs-container');
	        var tab_num = $(this).attr('data-tab');
	        
	       
	        var content = container.find('.product-info-tab-content[data-tab="' + tab_num + '"]');
	        
	        if( content.length > 0 ) {
		        container.find('.product-info-tab-content').addClass('hidden');
		        content.removeClass('hidden');
	        } 
        });
        
        $('.navbar-toggler').on('click', function() {
	       var nav = $(this);
	       var banner = $('header.banner');
	       var expanded = nav.attr('aria-expanded');
	       if( "false" === expanded ) {
		      banner.addClass('expanded');
	       } else {
		      banner.removeClass('expanded');
	       }
        });

        $('.mobilecontainer > .dropdown-submenu.mobilea > a:first-child').on('click', function(e) {
          e.preventDefault();
          e.stopPropagation();
          $(this).parent().toggleClass('open');
        });




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

         
        $( document ).ready(function() {

          //ADD TO CART OR CONTINUE TO CART
          //alert('test' + getCookie('PROD');
          //if (getCookie('PROD') > 0){
          //  alert('cookie working');
          //  $('.continuecart').removeClass('hidden');
          //}



          //ADD TO CONTACT FROM BASED ON SELECTION
          $('#contact_reason').change(function() {
              if ($(this).val() === '3' || $(this).val() === '4') {

                  $("#add_order_num").html('<input required type="text" placeholder="* Order/Search Number" name="docu_order" />');

              } else {
                  
                  $("#add_order_num").html(''); //empty


              }
          });



         





          //COOKIES FOR DISPLAYING CHECKOUT & LOGOUT LINKS IN HEADER

          var first = $(location).attr('pathname');
          first.indexOf(1);
          first.toLowerCase();
          cart_area = first.split("/")[1];

          if (cart_area != "cgi-bin") { //only load
            console.log('cart area', cart_area);

            document.getElementById('logout').style.display='none';

            var request = $.ajax({
              url: "https://secure.docusearch.com/cart/session",
              type: "GET",
              async: false,
              contentType: "application/json",
              xhrFields: {
                withCredentials: true
              }
            });

            
            request.done(function(response) {
              console.log('request to lumen success', response);

              if (response) {
                // if (true) {
                if (Object.keys(response.inCart).length > 0) {
                  console.log('userloggedin', Object.keys(response.inCart).length);
                  document.getElementById('thecartupdate').style.display='block';
                  /*document.getElementById('thecartupdate').style.background='#f47363';*/
                  setSession(response.inCart);
                } else {
                  setSession([]);
                  document.getElementById('thecartupdate').style.display='none';
                }

                if (response.userLoggedIn) {
                  console.log('userloggedin', response.userLoggedIn);
                  document.getElementById('logout').style.display='block';
                }
              }
            });
  
            request.fail(function(jqXHR, textStatus) {
              console.log( "Request failed: " + textStatus );
              document.getElementById('thecartupdate').style.display='none';
            });

            // if(getCookie2("LOGIN_ID") != null)
            // { 
            //   document.getElementById('logout').style.display='block';
            // }

            // if (getCookie2("cartcount")) {
             
            //    var total_items = getCookie2("cartcount");
            //    if (total_items > 0) {

            //      document.getElementById('thecartupdate').style.display='block';
            //      /*document.getElementById('thecartupdate').style.background='#f47363';*/

            //    } else {
            //     document.getElementById('thecartupdate').style.display='none';
            //    }

            // } else {
            //    document.getElementById('thecartupdate').style.display='none';
            // }
          }

        

          //$('.rating1').raty({ readOnly: true, score: 3 });
          $('.rating1').raty({
            readOnly: function() {
              return true;
            },
            score: function() {
              return $(this).attr('data-score');
            }
          });


          //No link redirect on mobile

          $('.mobilecontainer .mobilea').children('a').attr("href", "#");



       

        $( window ).resize(function() {
          var headerheight = $("header").outerHeight();
          $(".wrap").css("margin-top", headerheight);
        });


         
          //first fb function
        $(".fbclick").click(function(){
          //open facebook page in new tab
         // window.open('http://www.facebook.com/docusearch');
          //get current like count
          var request = $.ajax({
            url: "/fblike.php",
            type: "GET",      
            dataType: "html"
          });

          request.done(function(msg) {
            $("#displayCount").html(msg);      
          });

          request.fail(function(jqXHR, textStatus) {
            alert( "Request failed: " + textStatus );
          });

        });



        //fb close notification & set cookie
        $("#closenotice").click(function(){
          $(".noticecontainer").hide();
          var d = new Date();
          d.setTime(d.getTime() + 12*60*60*1000); //12 hours
          var expires = "expires=" + d.toGMTString();
          document.cookie = "noticeadvisory=1; "+ expires +"; path=/";
      
        });


        //REDIRECT USER ON SELECT
        $('.searchtype').change(function() {
          window.location.assign($(this).val());
        }); 

        //SHOW MORE REVIEWS
        $('.reviews-all-btn').on('click','a', function() {
          
          var get_prev_count = $(".reviewscount").val();
          var get_total_reviews = $(".totalreviews").val();
          var prev_count = parseInt(get_prev_count);
          var total_reviews = parseInt(get_total_reviews);
          var end_reviews = (total_reviews - 5) / 5;
              var end_reviews = Math.ceil(end_reviews);
            //alert(end_reviews);


          var cur_count = prev_count + 1;
          var the_batch = ".batch" + cur_count;

          if ( cur_count < end_reviews) { //more reviews to be shown
            $( the_batch ).removeClass('hidden');
            $('.reviewscount').attr('value',cur_count);
          } else if ( cur_count == end_reviews) { //show last of reviews and switch btn text
            $( the_batch ).removeClass('hidden');
            $('.reviewscount').attr('value',cur_count);
            $( ".reviews-all-btn a" ).text( "See less Reviews" );
          } else if (cur_count > end_reviews) { //see less reviews
            $(".reviews-more").addClass('hidden');
            $( ".reviews-all-btn a" ).text( "See more Reviews" );
            $('.reviewscount').attr('value','0');
            
          }
        });


        //VIDEO POPUP
        // $('.prodpopup').click(function() { //auto start YT video on modal load
        //   var vidurl = jQuery("#videoModal iframe").attr("src");
        //   var vidurlchange = vidurl.replace("autoplay=0", "autoplay=1"); 
        //   $("#videoModal iframe").attr('src', vidurlchange); 
        // });
        
        $('#videoModal').on('shown.bs.modal', function (e) {
          var vidurl = jQuery("#videoModal iframe").attr("src");
          var vidurlchange = vidurl.replace("autoplay=0", "autoplay=1"); 
          $("#videoModal iframe").attr('src', vidurlchange); 
        })
        
        $('#videoModal').click(function () { //stop vide0 play on home and footer video
          var vidurl = jQuery("#videoModal iframe").attr("src");
          var vidurlchange = vidurl.replace("autoplay=1", "autoplay=0"); 
          $('#videoModal iframe').attr("src", vidurlchange);
        }); 


        
        //STICKY
            var stickyToggle = function(sticky, stickyWrapper, scrollElement) {
              var stickyHeight = sticky.outerHeight();
              var stickyTop = stickyWrapper.offset().top;
              if (scrollElement.scrollTop() >= stickyTop){
                stickyWrapper.height(stickyHeight);
                sticky.addClass("is-sticky");
              }
              else{
                sticky.removeClass("is-sticky");
                stickyWrapper.height('auto');
              }
            };
            
            // Find all data-toggle="sticky-onscroll" elements
            $('[data-toggle="sticky-onscroll"]').each(function() {
              var sticky = $(this);
              var stickyWrapper = $('<div>').addClass('sticky-wrapper'); // insert hidden element to maintain actual top offset on page
              sticky.before(stickyWrapper);
              sticky.addClass('sticky');
              
              // Scroll & resize events
              $(window).on('scroll.sticky-onscroll resize.sticky-onscroll', function() {
                stickyToggle(sticky, stickyWrapper, $(this));
              });
              
              // On page load
              stickyToggle(sticky, stickyWrapper, $(window));
            });



        
       
        //CHECKOUT URL CHANGES & ALERTS          

          //js function  getting url params
          var getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
              sURLVariables = sPageURL.split('&'),
              sParameterName,
              i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : sParameterName[1];
                }
            }
          };


      //amp: initiate first fb function if param is set

        var fblike = getUrlParameter('fblike');

        if (fblike !== undefined) {
          $(".fbclick").trigger('click');
        }

      //amp: initiate upsell function if param is set

        var amp_upsell = getUrlParameter('upsell');
        var amp_qty = getUrlParameter('qty');

        if (amp_upsell !== undefined) {
          $(".initiateupsell").trigger('click');
          $("#qty-field").val(amp_qty);

        }


         


          //CHANGE ORDER BUTTON TO CONTINUE CHECKOUT IF PRODUCT IS IN CART
          var checkout_url = $(".rawcheckouturl").val();
          if (typeof checkout_url != 'undefined') {
            
            // var the_item = checkout_url.split('/');
            // var the_item_param = the_item[5];
            // var search_code_arr = the_item_param.split('=');
            // var search_code = search_code_arr[1];

            // Added by eldrin
            // This is for setting the search_code for the cookies and other stuff
            var the_item = checkout_url.split('/');
            var search_code = the_item[4];
            // Below added by keith
            // var search_code = 'order_data';
            var total_in_cart = getInCart(search_code);
            
            //alert('total: ' + total_in_cart);
            var checkout_btn = "Continue Checkout (" + total_in_cart + ")";
            if (total_in_cart > 0){
                      $('.continuecart').removeClass( "hidden" );
                      $('.anothersearch').removeClass( "hidden" );

                      $('.qty-container').addClass( "hidden" );
                      $('.neworderurl').addClass( "hidden" );
                      $('.orderBtn').addClass( "hidden" );
                      $( ".continuecart" ).text(checkout_btn);
                      console.log('has same product in cart');
            }
            $(".shownormal").click(function(){ //revert to normal checkout

              $('.continuecart').addClass( "hidden" );
              $('.anothersearch').addClass( "hidden" );
              $('.orderBtn').removeClass( "hidden" );
              $('.qty-container').removeClass( "hidden" );
              $('.neworderurl').removeClass( "hidden" );

            });
          }


          //Add cart cookie as soon as 'Order Now' btn is clicked
          //Create cookie on checkout or add another search button
          $('.neworderurl').click(function(){ 
            setCookie2('cartcount','1', 1); //1day
             
            //var search_code = getUrlVars()["item"];
            var new_qty = $(".qtyselect").val(); 
            if(getInCart(search_code)) {
              var search_code_total = Number(getInCart(search_code)) + Number(new_qty);
              //alert('alert neg 0');
            } else {
              var search_code_total = new_qty;
              //alert('alert 0');
            }
              
            //add to inCart cookie
              ///START LOOOP BASED ON new_qty
              for(var i=0; i<new_qty; i++) {
                if(getCookie2('inCart')) {//getCookie2(inCart)!=0 && getCookie2(inCart)!=''
                  //alert('alert 1');
                  var cart_values = getCookie2('inCart') + '|' + search_code;
                  setCookie2('inCart',cart_values,1); //reset inCart cookie for more than one searches


                } else { //set inCart cookie for first time
                  //alert('alert 2');
                  setCookie2('inCart',search_code,1); //all searches in cart, i.e.  DOSC|PROD
                
                }
              }
              ////END LOOP

            

            setCookie2(search_code, search_code_total, 1); //ie: Search Code {DOSC}, Number of searches ordered for this search type, 1 day exp
           
          });

          



          //CALCULATE CONTENT MARGIN FOR FLOATING HEADER
          var headerheight = $("header").outerHeight();
          $(".wrap").css("margin-top", headerheight);
          

          
          //set the order quantity to order url when page is first loaded or refreshed
          //this is for access fees, if qty is passed via url param
          var fee_param = getUrlParameter('fee'); 
          var qty_param = getUrlParameter('qty'); 

          var new_qty = 0;
          if (fee_param !== undefined) {
            new_qty = qty_param;
            if (qty_param !== undefined) {
              $("#qty-field").val(qty_param);
            }

            $(function() {
              $('#myModalFee').modal();
            });
          
            $(function() {
              $("#text-one").change(function() {
                $("#text-two").load("ajax/state/" + $(this).val() + ".txt");
              });
            });
          
            //alert(qty_param);
          } else {
            new_qty = $(".qtyselect").val(); //grab current QTY  
            //alert('grab current QTY');    
          }

          var raw_url = $(".rawcheckouturl").val() ? $(".rawcheckouturl").val(): ''; //grab current order URL with no QTY
          //var new_order_url = raw_url + '/buy=' + new_qty;
          var new_order_url = raw_url + '/' + new_qty;
          var clean_url = raw_url.split('?');
          var params = (typeof clean_url[1] === "undefined") ? '' : clean_url[1];
          var new_order_url = clean_url[0] + '/' + new_qty + '?' + params;
          var new_order_url_fee = '?fee=1&qty=' + new_qty;
          console.log(new_order_url);

          $('.neworderurl').attr('href',new_order_url);
          $('.neworderurl_fee').attr('href',new_order_url_fee);


          //order quantity change GET (replicated below on event change)
          $(".qtyselect").change(function(){
            
            

            var raw_url = $(".rawcheckouturl").val(); //grab current order URL with no QTY
            var new_qty = this.value; //$(".qtyselect").val(); //grab current QTY
            //var new_order_url = raw_url + '/buy=' + new_qty;
            var clean_url = raw_url.split('?');
            var params = (typeof clean_url[1] === "undefined") ? '' : clean_url[1];

            var new_order_url = clean_url[0] + '/' + new_qty + '?' + params;
            var new_order_url_fee = '?fee=1&qty=' + new_qty;
            console.log('in qty select', new_order_url);

            $(".qtyselect").val(new_qty);
            $('.neworderurl').attr('href',new_order_url);
            $('.neworderurl_fee').attr('href',new_order_url_fee);
          
            
           // alert(new_order_url);
          });

           //change search value (bank account search state or nationwide)
          $(".upgradeoption").click(function(){
            var upgvalue = $(this).attr("href");
            
            if (upgvalue == '#upgrade') {
              var orderid = 'FINH'; //nationwide
            } else {
              var orderid = 'FINF'; //single state
            }

            var raw_url = $(".rawcheckouturl").val(); //grab current order URL with no QTY
            var new_qty = $(".qtyselect").val(); //grab current QTY
            // var new_order_url = raw_url + '/buy=' + new_qty + orderid;
            var new_order_url = 'https://secure.docusearch.com/checkout/' + orderid + '/' + new_qty;
            $('.neworderurl').attr('href',new_order_url);
            
           // alert(new_order_url);
          });





          //fee warning
          $(".feewarning").change(function(){
            var new_qty = $(".qtyselect").val(); //grab current QTY
            if(new_qty > 1) {
              var qtymsg = "Multiple searches must be from the same county. If you need to select more than one county, please order the searches individually.";
              alert(qtymsg);
            }
          });

          $(".feewarning2").change(function(){
            var new_qty = $(".feewarning2").val(); //grab current QTY
            if(new_qty > 1) {
              var qtymsg = "Multiple searches must be from the same county. If you need to select more than one county, please order the searches individually.";
              alert(qtymsg);
            }
          });

          

          
          




        }); //end .ready




      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        /*$('.moresearch').click(function(e) { 
            e.preventDefault();
            //$( ".more-search-types div:hidden" ).addClass('show');
            $(".hidefive:hidden").show();
            $(this).removeClass( "moresearch" ).addClass( "lesssearch" );
            $( ".lesssearch .searchoptions" ).text( "See less search types" );


        });*/

        

      
        


        


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
          
        $( document ).ready(function() {

            //$('.toggleon').click(function(e) { 
            $('.container').on('click','.toggleon', function(e) { 
                e.preventDefault();

                if ($(".togglebox:hidden")) {
                 
                  $( ".togglebox:hidden" ).addClass( "show" );
                  $('#maintoggle').removeClass( "toggleon" );
                  $('#maintoggle').addClass( "toggleoff" );  
                  $( ".searchoptions" ).text( "See less search types" );

                } 
            });

            $('.container').on('click','.toggleoff', function(e) { 
                e.preventDefault();

                 
                  //$( ".togglebox.show" ).addClass( "hide" );
                  $( ".togglebox.show" ).removeClass( "show" );
                  $('#maintoggle').removeClass( "toggleoff" );
                  $('#maintoggle').addClass( "toggleon" );  
                  $( ".searchoptions" ).text( "See more search types" );

                
            });

           

         
  
        });

        
        

      

      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // JavaScript to be fired on the contact page
       

      }
    }
  };
  
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.




/*$('.more-search-types').on('click', function(e){
          e.preventDefault();
          var theclass = $(this).hasClass("moresearch");
          
          if(theclass == true) { //if button displayed for show more searches

            var row = $('.service-block-row:visible'),
                next_row = row.next();
                //console.log("row", row, next_row);
                
            if( next_row.hasClass('hidden') ) {
              next_row.removeClass('hidden');
              next_row.addClass('openedrow');
              var final_row = next_row.next('.service-block-row:visible');
              if( final_row.length == 0 ) {
                //$(this).remove();
                $(this).removeClass( "moresearch" ).addClass( "lesssearch" ); //not necessary
                $( ".lesssearch .searchoptions" ).text( "See less search types" );
              }
            }

          } else { //if button displayed for show less searches

            
            $( ".openedrow" ).addClass('hidden');
            $( ".openedrow" ).removeClass('openedrow');

            $(this).removeClass( "lesssearch" ).addClass( "moresearch" ); //not necessary
            $( ".moresearch .searchoptions" ).text( "See more search types" );

          }
         
        });*/
